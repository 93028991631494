import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { TASService} from '../../services/tas.service';

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  PhotoURL: string;
}

export interface Option {
  role: string;
}

@Component({
  selector: 'app-team-editor',
  templateUrl: './team-editor.component.html',
  styleUrls: ['./team-editor.component.css']
})
export class TeamEditorComponent implements OnInit {
  @Output() teamUpdated = new EventEmitter<boolean>();

  staffFormGroup: FormGroup;
  selRoles = new FormControl();
  selTeam = new FormControl();
  options: Option[];
  teammembers: User[];


  filteredOptions: Observable<User[]>;

  constructor(private fb: FormBuilder, private tasService: TASService) { }

  ngOnInit() {
    this.getRoles();
    this.getTeamMembers();

    this.staffFormGroup = this.fb.group({
      staffID: ['', Validators.required],
      role: ['', Validators.required],
      Location: ['sacramento', Validators.required],
      EDRegion: ['Rocket', Validators.required]
    });
  }



  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.teammembers.filter(option => option.firstName.toLowerCase().indexOf(filterValue) === 0);
  }

  displayFn(user?: User): string | undefined {
    return user ? user.firstName + ' ' + user.lastName : undefined;
  }



  private getRoles() {
    this.tasService.get('roles').subscribe((res: any) => {
      this.options = res;

    });
  }

  private getTeamMembers() {
    this.tasService.get('teammembers').subscribe((results: any) => {
      this.teammembers = results;

      this.filteredOptions = this.selTeam.valueChanges
        .pipe(
          startWith(''),
          map(value => typeof value === 'string' ? value : value.firstName),
          map(firstName => firstName ? this._filter(firstName) : this.teammembers.slice())
        );
    });
  }

  onSubmit() {

    this.staffFormGroup.value.staffID = this.selTeam.value.id;
    this.staffFormGroup.value.role = this.selRoles.value;
    this.staffFormGroup.value.status = 'active';
    this.tasService.add(this.staffFormGroup.value, 'teams').subscribe((res: any) => {

      this.teamUpdated.emit(true);

    });

  }
}

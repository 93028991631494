import { Component, OnInit } from '@angular/core';
import { TASService} from '../../../services/tas.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { HubConnection } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';

export interface Message {
  firstName: string;
  lastName: string;
  timeStamp: string;
  message: string;
}

@Component({
  selector: 'app-message-view',
  templateUrl: './message-view.component.html',
  styleUrls: ['./message-view.component.css']
})
export class MessageViewComponent implements OnInit {
  private _hubConnection: HubConnection | undefined;
  durationInSeconds = 10;

  public msgData: Message[];
  constructor(private tasService: TASService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('https://tascoreapi.com/messages')
      .configureLogging(signalR.LogLevel.Information)
      .build();
    // .withUrl('http://13.56.168.101/rocketbackend/messages')

    this._hubConnection.start().catch(err => console.error(err.toString()));

    this._hubConnection.on('BroadcastMessage', (data: any) => {

      this.refreshView();

      this._snackBar.open(data.message, 'New Message', {
        duration: this.durationInSeconds * 1000
      });
    });

    this.refreshView();
  }


  refreshView() {
    this.tasService.get('team_message').subscribe((res: any) => {
      this.msgData = res;
    });

  }


  hideMessage(thisMessage: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Are you sure that you want to remove this message?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        thisMessage.isVisible = false;
        this.tasService.update(thisMessage, 'team_message').subscribe((res: any) => {
          this.refreshView();
        });
      }
    });
  }
}

import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { TASService } from '../../services/tas.service';

export interface Patient {
  los: number;
  age: string;
  chiefComplaint: string;
  acuity: string;
  patientNumber: number;
}
@Component({
  selector: 'app-patients',
  templateUrl: './patients.component.html',
  styleUrls: ['./patients.component.css']
})
export class PatientsComponent implements OnInit, AfterViewInit {

  // public panelOpened: boolean;

  componentActivated = false;
  @Input() ProviderID: number;
  @Input() inputPanelOpen: boolean;
  public patientData: Patient[];

  public patientCount = 0;
  public isPanelOpen = false;

  constructor(public tasService: TASService) {
    console.log('constructor patients component');
  }

  ngOnInit() {
    // const baseURL = this.tasService.accessPointUrl;
    this.patientCount = 0;
    this.patientData = [];
    this.isPanelOpen = this.inputPanelOpen;

    // this.panelOpened = this.isPanelOpen;
    if (!this.inputPanelOpen) {
      this.inputPanelOpen = false;
    }


    this.refreshView();
    console.log('onInit patients component');
  }

  ngAfterViewInit() {
    this.componentActivated = true;
  }

  refreshView() {

    console.log(this.inputPanelOpen);
    console.log(this.patientData);
    if (!this.ProviderID) {
      this.ProviderID = 0;
    }
    this.tasService.getPatientsforID(this.ProviderID).subscribe((res: any) => {
      this.patientData = res;
      this.patientCount = this.patientData.length;
      console.log(this.patientData);
    });

  }
togglePanel() {
  this.inputPanelOpen = !this.inputPanelOpen;

  console.log(this.inputPanelOpen);
  }

   getColorByAcuity(acuity): string {
    switch (acuity) {
      case '3': {
        return 'primary';
      }
      case '2': {
        return 'accent';
      }
    }
  }
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TASService {
  private address: string;
  private headers: HttpHeaders;
  public baseAccessPointURL: string = 'https://tascoreapi.com/api/';
  // public baseAccessPointURL: string = 'http://localhost:60126/api/';
  public accessPointUrl: string = this.baseAccessPointURL;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
  }

  public get(controller) {
    // Get all jogging data
    return this.http.get(this.baseAccessPointURL + controller, {headers: this.headers});
  }

  public getPatientsforID(providerID) {
    // Get all jogging data
    return this.http.get(this.accessPointUrl + 'patients/' + providerID , {headers: this.headers});
  }

  public add(payload, controller) {
    return this.http.post(this.baseAccessPointURL + controller, payload, {headers: this.headers});
  }

  public remove(payload, controller) {
    return this.http.delete(this.accessPointUrl + controller + '/' + payload.id, {headers: this.headers});
  }

  public update(payload, controller) {
    return this.http.put(this.accessPointUrl  + controller + '/' + payload.id, payload, {headers: this.headers});
  }
  public getPhoto(thisURL: string): string {

    let photoLocation = '';
    if (!thisURL) {
      photoLocation = 'https://img-new.cgtrader.com/items/712021/5206207783/toy-rocket-3d-model-low-poly-obj-mtl-3ds-fbx-blend-dae-ply.png';
    } else {
      if (thisURL.startsWith('.')) {
        photoLocation = 'kaizentas.com/tas' + thisURL.slice(1);
      } else {
        photoLocation = thisURL;
      }
    }
    return photoLocation;
  }
}

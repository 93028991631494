

import {Component, OnInit, ViewChild, Input, AfterViewInit, AfterViewChecked} from '@angular/core';
import { timer } from 'rxjs';
import { TASService} from '../../services/tas.service';
import { TeamViewComponent } from '../team-view/team-view.component';
// import {DocCardComponent, User} from '../doc-card/doc-card.component';
import { MessageViewComponent } from '../messaging/message-view/message-view.component';
import { IssueViewComponent } from '../issues/issue-view/issue-view.component';
import { PatientsComponent } from '../patients/patients.component';

export interface Provider {
  id: number;
  firstName: string;
  lastName: string;
  photoURL: string;
  min_left: number;
  evenOdd: string;
  min_into_RocketStart: number;
  status: string;
  rocketStart: Date;
  rocketEnd: Date;

  rdaStart: Date;
  rdaEnd: Date;
  min_into_RDA: number;
}


@Component({
  selector: 'app-main-display',
  templateUrl: './main-display-alt.component.html',
  styleUrls: ['./main-display.component.css']
})
export class MainDisplayComponent implements OnInit, AfterViewInit, AfterViewChecked {

  public isCollapsed = false;
  @Input() UserID: any;
  @Input() Location: any;
  @Input() EDRegion: any;

  @ViewChild(TeamViewComponent, { static: false }) teamView: TeamViewComponent;
  // @ViewChild(DocCardComponent, { static: false }) docCard: DocCardComponent;
  @ViewChild(MessageViewComponent, { static: false }) msgView: MessageViewComponent;
  @ViewChild(IssueViewComponent, { static: false }) issueView: IssueViewComponent;
  @ViewChild(PatientsComponent, { static: true })
  @ViewChild(PatientsComponent, { static: true }) ptView: PatientsComponent;

  private refreshTimer;
  public patientsOpen = false;
  public providerData: Provider[] = [];
  public RDAData: Provider[] = [];
  // public ptCount = 0;
  constructor(private tasService: TASService) { }

  ngOnInit() {


    this.refreshTimer = timer(0, 60000);
    this.refreshTimer.subscribe((t) => this.refreshPage());

  }


  // ngAfterViewChecked() {
  //   console.log('patientsOpen: ' + this.patientsOpen);
  // }

  ngAfterViewInit() {


  }

  private refreshPage() {
    this.tasService.get('rocketingdocs').subscribe((res: any) => {
      this.providerData = res;
    });

    this.tasService.get('rdadocs').subscribe((res: any) => {
      this.RDAData = res;
    });

    this.teamView.refreshView();
    // this.docCard.refreshCard();
    this.msgView.refreshView();
    this.issueView.refreshView();
    // this.ptView.refreshView();


    // this.ptCount = this.ptView.patientCount;
  }


  onChildUpdated(result: boolean) {
    this.refreshPage();
  }

  ngAfterViewChecked(): void {
  }


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: `./app.component.html`,
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'rocketdisplay';

  public UserID: number;
  public Location: string;
  public EDRegion: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.UserID = params.userid;
      this.Location = params.location;
      this.EDRegion = params.EDRegion;
    });

  }

}

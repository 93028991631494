import { Component, OnInit, Input } from '@angular/core';
import {ThemePalette} from '@angular/material/core';


export interface Provider {
  id: number;
  firstName: string;
  lastName: string;
  photoURL: string;
  min_left: number;
  evenOdd: string;
  min_into_RocketStart: number;
  status: string;
  rocketStart: Date;
  rocketEnd: Date;

  rdaStart: Date;
  rdaEnd: Date;
  min_into_RDA: number;
}

@Component({
  selector: 'app-doc-card',
  templateUrl: './doc-card.component.html',
  styleUrls: ['./doc-card.component.css']
})
export class DocCardComponent implements OnInit {
  // @Input()
  // docStatus: string;
  @Input()
  provider: Provider;

  @Input()
  cardType: string;

  // public userData: User[] = [];

  constructor() { }

  ngOnInit() {
    console.log(this.cardType);
    this.refreshCard();
  }

  refreshCard() {
    // this.rocketdocsService.get().subscribe((res: any) => {
    //   this.userData = res;
    // });
  }

  public getColor(thisMinutesRemaining: number): ThemePalette {
    if (thisMinutesRemaining < 10) {
      return 'warn';
    } else {
      return 'primary';
    }
  }

  public getPhoto(thisURL: string): string {

    let photoLocation = '';
    if (!thisURL) {
      photoLocation = 'https://img-new.cgtrader.com/items/712021/5206207783/toy-rocket-3d-model-low-poly-obj-mtl-3ds-fbx-blend-dae-ply.png';
    } else {
      if (thisURL.startsWith('.')) {
        photoLocation = 'http://kaizentas.com/tas' + thisURL.slice(1);
      } else {
        photoLocation = thisURL;
      }
    }
    return photoLocation;
  }

}

import { Component, OnInit } from '@angular/core';
// import { TeamService } from '../../services/team.service';
import { ConfirmationDialogComponent } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';
import { TASService} from '../../services/tas.service';
import { HubConnection } from '@aspnet/signalr';
import * as signalR from '@aspnet/signalr';

@Component({
  selector: 'app-team-view',
  templateUrl: './team-view.component.html',
  styleUrls: ['./team-view.component.css']
})
export class TeamViewComponent implements OnInit {
  private _hubConnection: HubConnection | undefined;

  displayedColumns: string[] = ['role', 'photoURL', 'name', 'status', 'offUnit'];

  public teamData = [];

  newLocation = 'sacramento';
  newEDRegion = 'Rocket';

  constructor(public tasService: TASService, public dialog: MatDialog) { }

  ngOnInit() {
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('https://tascoreapi.com/teamUpdates')
      .configureLogging(signalR.LogLevel.Information)
      .build();
    // .withUrl('http://13.56.168.101/rocketbackend/messages')

    this._hubConnection.start().catch(err => console.error(err.toString()));

    this._hubConnection.on('BroadcastTeamUpdate', (data: any) => {

      this.refreshView();

    });

    this.refreshView();
  }

  refreshView() {
    this.tasService.get('teams').subscribe((res: any) => {
      this.teamData = res;
      // console.log(this.teamData);
    });

  }

  public toggleBreak(thisUser: any) {
    let message = ' going on break?';
    if (thisUser.status === 'break') {
      message = ' returning from break?';
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this.staffName(thisUser)  + message
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        thisUser.location = this.newLocation;
        thisUser.EDRegion = this.newEDRegion;

        if (thisUser.status === 'break') {
          thisUser.status = 'active';
        } else {
          thisUser.status = 'break';
        }
        this.tasService.update(thisUser, 'teams').subscribe((res: any) => {
          this.refreshView();
        });
      }
    });
  }

  public exitUnit(thisUser: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: this.staffName(thisUser) + ' leaving the Rocket area?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.tasService.remove(thisUser, 'teams').subscribe((res: any) => {
          this.refreshView();
          // console.log(this.teamData);
        });
      }
    });
  }

  public staffName(thisUser: any): string {
    return thisUser.firstName + ' ' + thisUser.lastName.substring(0, 1) + '.';
  }

}

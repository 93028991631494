import { Component, OnInit } from '@angular/core';
import { HubConnection } from '@aspnet/signalr';
import { TASService } from '../../../services/tas.service';
import * as signalR from '@aspnet/signalr';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material';

export interface Issue {
  issue: string;
  category: string;
  reported: string;
  reportingNotes: string;
  room: string;
  firstName: string;
  lastName: string;
}

@Component({
  selector: 'app-issue-view',
  templateUrl: './issue-view.component.html',
  styleUrls: ['./issue-view.component.css']
})
export class IssueViewComponent implements OnInit {
  private _hubConnection: HubConnection | undefined;
  durationInSeconds = 10;
  public issueData: Issue[];

  constructor(private tasService: TASService, private _snackBar: MatSnackBar, public dialog: MatDialog) { }

  ngOnInit() {
    this._hubConnection = new signalR.HubConnectionBuilder()
      .withUrl('https://tascoreapi.com/issues')
      .configureLogging(signalR.LogLevel.Information)
      .build();
    // .withUrl('http://13.56.168.101/rocketbackend/messages')

    this._hubConnection.start().catch(err => console.error(err.toString()));

    this._hubConnection.on('BroadcastIssue', (data: any) => {

      this.refreshView();

      this._snackBar.open(data.issue, 'New Issue', {
        duration: this.durationInSeconds * 1000
      });
    });

    this.refreshView();
  }

  refreshView() {
    this.tasService.get('Team_Issue').subscribe((res: any) => {
      this.issueData = res;
      // console.log(this.issueData);
    });

  }

  completeIssue(thisIssue: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '350px',
      data: 'Are you sure that you want to mark this issue as resolved?'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        thisIssue.isResolved = true;
        this.tasService.update(thisIssue, 'team_issue').subscribe((res: any) => {
          this.refreshView();
          // console.log(this.teamData);
        });
      }
    });
  }
}

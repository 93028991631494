import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TASService} from '../../../services/tas.service';

@Component({
  selector: 'app-add-message',
  templateUrl: './add-message.component.html',
  styleUrls: ['./add-message.component.css']
})
export class AddMessageComponent implements OnInit {
  @Input() UserID: any;
  @Input() Location: any;
  @Input() EDRegion: any;

  @Output() messagesUpdated = new EventEmitter<boolean>();

  msgForm: FormGroup;
  inpMessage = new FormControl();

  constructor(private fb: FormBuilder, private tasService: TASService) { }

  ngOnInit() {

    this.msgForm = this.fb.group({
      Message: ['', Validators.required],
      Location: ['sacramento', Validators.required],
      EDRegion: ['Rocket', Validators.required],
      UserID: [],
      isVisible: [true]
    });
  }

  addMessage(thisMessage: any) {

    this.msgForm.value.Message = thisMessage;

    this.msgForm.value.UserID = !this.UserID ? 0 : this.UserID;
    this.msgForm.value.Location = !this.Location ? 'sacramento' : this.Location;
    this.msgForm.value.EDRegion = !this.EDRegion ? 'Rocket' : this.EDRegion;


    this.tasService.add(this.msgForm.value, 'team_message').subscribe((res: any) => {

      this.messagesUpdated.emit(true);

    });
  }


}

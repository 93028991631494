import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { TASService } from '../../../services/tas.service';

export interface IssueType {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-issue',
  templateUrl: './add-issue.component.html',
  styleUrls: ['./add-issue.component.css']
})

export class AddIssueComponent implements OnInit {
  @Input() UserID: any;
  @Input() Location: any;
  @Input() EDRegion: any;

  @Output() issuesUpdated = new EventEmitter<boolean>();

  issueForm: FormGroup;

  issueTypes: IssueType[] = [
    {value: 'equipment', viewValue: 'Equipment'},
    {value: 'supplies', viewValue: 'Supplies'},
    {value: 'workflow', viewValue: 'Workflow'},
    {value: 'other', viewValue: 'Other'}
  ];
  constructor(private fb: FormBuilder, private tasService: TASService) { }

  ngOnInit() {
    this.issueForm = this.fb.group({
      ReportedBy: [1],
      Issue: ['', Validators.required],
      Category: ['', Validators.required],
      Location: ['sacramento', Validators.required],
      EDRegion: ['Rocket', Validators.required],
      Status: ['reported'],
      ReportingNotes: [''],
      Room: [],
      isActive: [true]
    });
  }

  addIssue() {
    console.log(this.issueForm.value);

    this.issueForm.value.ReportedBy = !this.UserID ? 0 : this.UserID;
    this.issueForm.value.Location = !this.Location ? 'sacramento' : this.Location;
    this.issueForm.value.EDRegion = !this.EDRegion ? 'Rocket' : this.EDRegion;

    this.issueForm.value.Category = this.issueForm.value.Category.value;

    this.tasService.add(this.issueForm.value, 'team_issue').subscribe((res: any) => {
      console.log(res);

      this.issuesUpdated.emit(true);

    });
  }

}
